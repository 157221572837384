import { graphql, PageProps } from "gatsby";
import { ContentfulRichTextGatsbyReference } from "gatsby-source-contentful/rich-text";
import React, { useState } from "react";
import Footer from "../components/footer/footer";
import Layout from "../components/layout";
import SimpleNavbar from "../components/navbar/simple-navbar";
import StickyNavbar from "../components/navbar/sticky-navbar";
import renderBlocks, { Block } from "../components/render-blocks";
import RichText from "../components/rich-text/rich-text";
import NavbarHeightContext from "../contexts/navbar-height-context";
import useMetaFields, { MetaFieldsData } from "../hooks/use-meta-fields";

type PageData = {
  contentfulPage: MetaFieldsData & {
    blocksAfterBody?: Block[];
    blocksBeforeBody?: Block[];
    body: {
      raw: string;
      references: ContentfulRichTextGatsbyReference[];
    };
    layout: "Landing" | "Legal";
  };
};

type PageContextProps = {
  route: string;
};

const Page = ({
  data,
  pageContext,
}: PageProps<PageData, PageContextProps>): JSX.Element => {
  const meta = useMetaFields(data.contentfulPage, pageContext.route);

  const [navbarHeight, setNavbarHeight] = useState<number>(0);

  const {
    contentfulPage: { blocksAfterBody, blocksBeforeBody, body, layout },
  } = data;

  const content = (
    <>
      {blocksBeforeBody &&
        renderBlocks(
          blocksBeforeBody,
          !blocksAfterBody && !body && <Footer inverse={true} />
        )}
      {body && (
        <>
          <div className="prose prose-h1:font-display prose-h1:tracking-wider mx-auto px-4 md:px-0 font-serif">
            <RichText>{body}</RichText>
          </div>
          {!blocksAfterBody && <Footer inverse={false} />}
        </>
      )}
      {blocksAfterBody &&
        renderBlocks(blocksAfterBody, <Footer inverse={true} />)}
    </>
  );

  return (
    <Layout meta={meta}>
      <div className={layout === "Landing" ? "bg-black" : "bg-white"}>
        {layout === "Landing" && (
          <div className="fixed inset-0">
            <div
              style={{
                backgroundImage: `url(/assets/background-blue.svg)`,
              }}
              className="absolute inset-0 bg-center bg-cover bg-no-repeat"
            />
            <div
              style={{
                backgroundImage: `url(/assets/rotator-blue.svg)`,
                height: "125vmax",
                left: "calc((125vmax - 100vw) / 2 * -1)",
                top: "calc((125vmax - 100vh) / 2 * -1)",
                width: "125vmax",
              }}
              className="absolute inset-0 bg-center bg-cover bg-no-repeat animate-spin-slow"
            />
          </div>
        )}
        {layout === "Landing" ? (
          <NavbarHeightContext.Provider
            value={{ height: navbarHeight, setHeight: setNavbarHeight }}
          >
            <StickyNavbar />
            {content}
          </NavbarHeightContext.Provider>
        ) : (
          <>
            <SimpleNavbar />
            {content}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query ($route: String!) {
    contentfulPage(fields: { route: { eq: $route } }) {
      blocksAfterBody {
        __typename
        ...Blocks
      }
      blocksBeforeBody {
        __typename
        ...Blocks
      }
      body {
        raw
        references {
          __typename
          ... on Node {
            ... on ContentfulAsset {
              ...Asset
            }
          }
        }
      }
      layout
      metaDescription {
        metaDescription
      }
      metaImage {
        file {
          url
        }
      }
      metaTitle
    }
  }
`;
