import { createContext } from "react";

type NavbarHeightContextValue = {
  height: number;
  setHeight: (height: number) => void;
};

const NavbarHeightContext = createContext<NavbarHeightContextValue>({
  height: 0,
  setHeight: () => {},
});

export default NavbarHeightContext;
