import { graphql, useStaticQuery } from "gatsby";
import React, { useContext, useEffect, useRef, useState } from "react";
import NavbarHeightContext from "../../contexts/navbar-height-context";
import useIntersectionObserver from "../../hooks/use-intersection-observer";
import Media, { MediaData } from "../media";
import MobileMenu from "../mobile-menu/mobile-menu";
import NavbarLinks from "./navbar-links";
import NavbarLogo from "./navbar-logo";

const LOGO_HEIGHT = 144;
const LOGO_PADDING = 32;

type StaticQueryData = {
  contentfulApplication: {
    siteLogo?: MediaData;
  };
};

const StickyNavbar = (): JSX.Element => {
  const data: StaticQueryData = useStaticQuery(graphql`
    query {
      contentfulApplication {
        siteLogo {
          file {
            contentType
            url
          }
          gatsbyImageData(width: 240)
          title
        }
      }
    }
  `);

  const logoRef = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState<boolean>(false);
  const stickyRef = useRef<HTMLDivElement>(null);

  const { setHeight } = useContext(NavbarHeightContext);

  useEffect(() => {
    const navbarHeight =
      LOGO_HEIGHT + LOGO_PADDING + (stickyRef.current?.offsetHeight || 0);

    setHeight(navbarHeight);
  }, [logoRef, stickyRef]);

  useIntersectionObserver(
    stickyRef,
    (entries) => setSticky(entries[0].intersectionRatio < 1),
    { threshold: [1] }
  );

  const {
    contentfulApplication: { siteLogo },
  } = data;

  return (
    <>
      {siteLogo && (
        <>
          <div
            ref={logoRef}
            style={{ paddingTop: LOGO_PADDING }}
            className="hidden lg:block relative z-20"
          >
            <Media data={siteLogo} height={LOGO_HEIGHT} />
          </div>
          <div className="md:hidden absolute top-0 left-0 z-20 w-full">
            <Media data={siteLogo} height={96} />
          </div>
          <div className="hidden md:block lg:hidden absolute top-0 left-0 z-20 w-full">
            <Media data={siteLogo} height={144} />
          </div>
        </>
      )}
      <div
        ref={stickyRef}
        className="sticky z-20 -top-px mt-2 md:mt-8 pt-8 pb-16 text-right"
      >
        {sticky && (
          <div className="absolute inset-0 bg-gradient-to-b from-black opacity-50" />
        )}
        <MobileMenu inverse={true} />
        <NavbarLinks
          logo={<NavbarLogo show={sticky} inverse={true} />}
          inverse={true}
        />
      </div>
    </>
  );
};

export default StickyNavbar;
